(function($)
{
    /**
     * Auto-growing textareas; technique ripped from Facebook
     *
     *
     * http://github.com/jaz303/jquery-grab-bag/tree/master/javascripts/jquery.autogrow-textarea.js
     */
    $.fn.autogrow = function(options)
    {
        return this.filter('textarea').each(function()
        {
            var self         = this;
            var $self        = $(self);
            var minHeight    = $self.height();
            var noFlickerPad = $self.hasClass('autogrow-short') ? 0 : parseInt($self.css('lineHeight')) || 0;
            var settings = $.extend({
                preGrowCallback: null,
                postGrowCallback: null
              }, options );

            var shadow = $('<div></div>').css({
                position:    'absolute',
                top:         -10000,
                left:        -10000,
                width:       $self.width(),
                fontSize:    $self.css('fontSize'),
                fontFamily:  $self.css('fontFamily'),
                fontWeight:  $self.css('fontWeight'),
                lineHeight:  $self.css('lineHeight'),
                resize:      'none',
    			'word-wrap': 'break-word'
            }).appendTo(document.body);

            var update = function(event)
            {
                var times = function(string, number)
                {
                    for (var i=0, r=''; i<number; i++) r += string;
                    return r;
                };

                var val = self.value.replace(/&/g, '&amp;')
                                    .replace(/</g, '&lt;')
                                    .replace(/>/g, '&gt;')
                                    .replace(/\n$/, '<br/>&#xa0;')
                                    .replace(/\n/g, '<br/>')
                                    .replace(/ {2,}/g, function(space){ return times('&#xa0;', space.length - 1) + ' ' });

				// Did enter get pressed?  Resize in this keydown event so that the flicker doesn't occur.
				if (event && event.data && event.data.event === 'keydown' && event.keyCode === 13) {
					val += '<br />';
				}

                shadow.css('width', $self.width());
                shadow.html(val + (noFlickerPad === 0 ? '...' : '')); // Append '...' to resize pre-emptively.

                var newHeight=Math.max(shadow.height() + noFlickerPad, minHeight);
                if(settings.preGrowCallback!=null){
                  newHeight=settings.preGrowCallback($self,shadow,newHeight,minHeight);
                }

                $self.height(newHeight);

                if(settings.postGrowCallback!=null){
                  settings.postGrowCallback($self);
                }
            }

            $self.change(update).keyup(update).keydown({event:'keydown'},update);
            $(window).resize(update);

            update();
        });
    };
})(jQuery);

$(document).ready(function(){
  $('.select-group').click(function(e){
    e.stopPropagation();
    $('.select-group').not(this).removeClass('active');
    $(this).addClass('active');
    $(this).addClass('touched');
  });
  $('.select-group ul.options').click(function(e){
    e.stopPropagation();
  });
  $('.select-group').keydown(function(event){
    var firstIndex = 0;
    var letter  = String.fromCharCode(event.keyCode);
    $(this).children('ul').scrollTop(0);
    if ($(this).hasClass('active')){
      $(this).children('ul').children('li').each(function(){
        if ($(this).text()[0]==letter){
            firstIndex = $(this).index()+1;
            return false;
        }
      });

      if (firstIndex>0){
        $(this).children('ul').scrollTop($(this).children('ul').children('li:nth-of-type('+firstIndex+')').position().top)
      }
    }
  })
  $('.select-group ul.options li a').click(function(e){
    e.stopPropagation();
    $(this).parent('li').parent('ul').parent('div').children('input').val($(this).attr('data-value')).trigger('change');
      $('.select-group').removeClass('active');


  });

  $('.toggle_search').click(function (e) {
      e.preventDefault();
      $('body').toggleClass('search');
  });
  $('.toggle_menu').click(function (e) {
      e.preventDefault();
      $('body').toggleClass('menu');
  });

  $(document).click(function(e){

      $('.select-group').removeClass('active');
      $('.select-nav').removeClass('active');
  });
  $('textarea').css('overflow', 'hidden').autogrow();


  $('.toggle .viewmore').click(function () {
    $(this).toggleClass('less');
    $(this).parent('header').parent('div').toggleClass('opened');
  });


  function showVideoPopup(id){
    var iframe = '<iframe width="480" height="270" src="'+id+'" frameborder="0"></iframe>';
    $(iframe).appendTo($('#videoplayer .target'));
    $('#videoplayer').show();
  }
  $('a.video').click(function (e){
    e.preventDefault();
    showVideoPopup($(this).attr('data-href'));
  })
  $('#videoplayer .close').click(function (e) {
    e.preventDefault();
    $('#videoplayer .target').html('');
    $('#videoplayer').hide();
  });

  $('.select-nav .trigger').click(function (e){
      e.preventDefault();
      e.stopPropagation();
      $(this).parent('nav').toggleClass('active');
  });
  $('.select-nav *').click(function (e){

      e.stopPropagation();

  })

  if ($('table.paged tr').length>10){
    $('.page-btn').show();
  }

  if ($('div.paged .toggle-item').length>10){
    $('.page-btn').show();
  }
$('.page-btn').click(function(e){
  $('.paged').removeClass('paged');
  $(this).hide();
})


$('a.share').click(function(e){
  e.preventDefault();
  var url = $(this).attr('href');
  window.open(url, '_blank', 'width=500,height=300');
})

function niceBytes(x){
  var units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    n = parseInt(x, 10) || 0,
    l = 0;
  while(n >= 1024){
      n = n/1024;
      l++;
  }
  return(n.toFixed(n >= 10 || l < 1 ? 0 : 1) + ' ' + units[l]);
}
$('input[name="pdf-file"]').change(function(evt){
  //console.log(this.files[0].name, niceBytes(this.files[0].size));
  $('#fileInfo').html('<strong>File:</strong> '+this.files[0].name+' ('+niceBytes(this.files[0].size)+')');

})
$('input[type="text"]').change(function(){
  if ($(this).val().length>0){
    $(this).addClass('has-value')
  } else{
    $(this).removeClass('has-value')
  }
})
$('.lectures .viewmore').click(function(){

  $(this).parent('td').parent('tr').toggleClass('more');
})
})
